'use client'

import { Book, Branch, Chapter, ChapterBranch } from '@prisma/client'
import Link from 'next/link'
import { Fragment, memo } from 'react'
import {
  getBookImage,
  getBookTitle,
  getBookUrl,
  isBlur,
} from 'src/app/lib/utils/book.util'
import { getChapterUrl } from 'src/helpers/global.helper'
import { t } from 'src/helpers/translate.helper'
import { BookImage } from '../../BookImage'
import { Date } from '../../Date/index'

// props.book as json string because we have hudration errors with date
export const UpdateCard = memo(function UpdateCard(props: {
  book: string
  isUserAuth: boolean
}) {
  const book = JSON.parse(props.book) as Book & {
    lastChapterBranch?: ChapterBranch & { chapter: Chapter; branch: Branch }
  }
  const { lastChapterBranch } = book
  const branchId = lastChapterBranch?.branchId || 0

  return (
    <div
      className="grid grid-cols-[auto_1fr] md:px-3 group px-2 border-t border-border py-3 gap-x-5"
      key={book.id}
    >
      <Link href={getBookUrl(book.slug)}>
        <BookImage
          isBlur={isBlur(props.isUserAuth, book)}
          src={getBookImage(book)}
          alt={getBookTitle(book)}
          commonClassName="rounded [&_>_div]:rounded w-[80px] dark:bg-black-600 bg-gray-200 h-[calc(theme(spacing.1)_*_30)]"
          width={80}
          height={120}
          isHoverEffect
        />
      </Link>
      <div className="grid grid-rows-[auto_auto_1fr] gap-y-2 py-1">
        <Link
          className="hover:text-primary leading-5 text-third line-clamp-2 font-semibold"
          href={getBookUrl(book.slug)}
        >
          {getBookTitle(book)}
        </Link>

        {lastChapterBranch && (
          <Fragment>
            <Link
              href={getChapterUrl({
                bookSlug: book.slug,
                chapterId: lastChapterBranch.chapterId,
                branchId,
              })}
              className="line-clamp-1 hover:text-primary"
            >
              {t('Tom {tom}, Chapter {chapter}', {
                tom: lastChapterBranch.chapter.tom,
                chapter: lastChapterBranch.chapter.number ?? '',
              })}
            </Link>
            <Date className="self-end" date={lastChapterBranch.createdAt} />
          </Fragment>
        )}
      </div>
    </div>
  )
})
